<template>
  <div class="box">
    <div class="item-box">
      <div class="label">服务地址：</div>
      <div
        class="content"
        @click="jumpAmap"
        style="display: flex;align-items: center;justify-content: space-between;"
      >
        <p class="placeholder" :style="{ color: address != '' ? 'black' : '' }">
          {{ address == "" ? "点击选择地址" : address }}
        </p>
        <span class="arrow_right"> <van-icon name="arrow" /> </span>
      </div>
    </div>
    <div class="item-box">
      <div class="label">门牌号：</div>
      <div class="content">
        <input
          type="text"
          class="input-style"
          v-model="addressDetail"
          placeholder="详细地址，如门牌号等"
        />
      </div>
    </div>
    <div class="item-box">
      <div class="label">联系人：</div>
      <div class="content">
        <input
          type="text"
          class="input-style"
          placeholder="请填写人姓名"
          v-model="name"
        />
      </div>
    </div>
    <div class="item-box">
      <div class="label">性别：</div>
      <div class="content">
        <van-radio-group v-model="sex" direction="horizontal">
          <van-radio name="1" icon-size="">
            男士
            <template #icon="props">
              <template v-if="props.checked">
                <img src="../../assets/img/choose-active.svg" class="sex-img" />
              </template>
              <template v-else>
                <img src="../../assets/img/choose.svg" class="sex-img" />
              </template>
            </template>
          </van-radio>
          <van-radio name="2">
            女士
            <template #icon="props">
              <template v-if="props.checked">
                <img src="../../assets/img/choose-active.svg" class="sex-img" />
              </template>
              <template v-else>
                <img src="../../assets/img/choose.svg" class="sex-img" />
              </template>
            </template>
          </van-radio>
        </van-radio-group>
      </div>
    </div>
    <div class="item-box">
      <div class="label">联系电话：</div>
      <div class="content">
        <input
          type="number"
          class="input-style"
          placeholder="请填写电话号码"
          v-model="phone"
        />
      </div>
    </div>

    <div class="submitSave" @click="submitSave">
      保存
    </div>
    <div
      class="submitSave deleteBtn"
      @click="deleteAddress"
      v-if="$route.query.id"
    >
      删除
    </div>
  </div>
</template>

<script>
import { isValidPhoneNumber } from "@/utils/index";
import {
  addAddress,
  getServeAddressDetail,
  editServeAddress,
  deleteServeAddress
} from "@/api/my";
import { getCityCode } from "@/api/index";
export default {
  data() {
    return {
      activeIcon: "https://img01.yzcdn.cn/vant/user-active.png",
      inactiveIcon: "https://img01.yzcdn.cn/vant/user-inactive.png",
      name: "",
      phone: "",
      sex: "1",
      address: "",
      addressInfo: null,
      addressDetail: ""
    };
  },
  methods: {
    jumpAmap() {
      this.$router.push("/amap");
    },
    async submitSave() {
      if (!isValidPhoneNumber(this.phone)) {
        this.$toast("请填写正确的手机号");
        return;
      }
      if (this.name == "" || this.address == "" || this.addressInfo == null) {
        this.$toast("请填写完整信息");
        return;
      }
      let _lnglat = this.addressInfo.address.location.split(",");
      const params = {
        consigneeName: this.name,
        consigneeMobile: this.phone,
        city: this.addressInfo.city,
        cityCode: this.addressInfo.cityCode,
        detailAddress: this.address.split(/[\t\r\f\n\s]*/g).join('') + " " + this.addressDetail,
        lng: _lnglat[0],
        lat: _lnglat[1],
        gender: this.sex,
        province: this.addressInfo.province,
        area: this.addressInfo.district
      };
      var res = await getCityCode({ locationCode: params.cityCode });
      if(res != null){
        params.cityCode = res.data;
      }
      console.log(params)
      if (this.$route.query.id) {
        params.id = this.$route.query.id;
        editServeAddress(params).then(res => {
          if (res.code == 10000) {
            this.$toast("修改成功");
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          }
        });
      } else {
        addAddress(params)
          .then(res => {
            if (res.code == 10000) {
              this.name =  ""
              this.phone =  "",
              this.sex =  "1",
              this.address = "",
              this.addressInfo = null,
              this.addressDetail =  ""
              this.$toast("添加成功");
              setTimeout(() => {
                this.$router.go(-1);
              }, 500);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    // 删除服务地址
    deleteAddress() {
      deleteServeAddress({ id: this.$route.query.id }).then(res => {
        if (res.code == 10000) {
          this.$toast("删除成功");
          setTimeout(() => {
            this.$router.go(-1);
          }, 500);
        }
      });
    },
    // 修改获取服务地址详情
    getServeAddressDetail() {
      getServeAddressDetail({ id: this.$route.query.id }).then(res => {
        this.address = res.data.detailAddress.split(" ")[0];
        this.addressDetail = res.data.detailAddress.split(" ")[1];
        this.name = res.data.consigneeName;
        this.sex = res.data.gender.toString();
        this.phone = res.data.consigneeMobile;
        let obj ={
          address:{
            location:""
          }
        }
        obj.address.location = res.data.lng+','+res.data.lat
        obj.province = res.data.province;
        obj.city = res.data.city;
        obj.cityCode = res.data.cityCode;
        obj.district = res.data.area;
        this.addressInfo = obj
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == "/my/service-address") {
      Object.assign(this.$data, this.$options.data.call(this));
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const selectAddress = JSON.parse(sessionStorage.getItem("selectAddress"));
      if (selectAddress) {
        sessionStorage.removeItem("selectAddress");
        console.log(selectAddress)
        vm.addressInfo = selectAddress;
        vm.address = selectAddress.address.name;
      } else if (vm.$route.query.id) {
        vm.getServeAddressDetail();
      }
    });
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/base.less";

.box {
  padding: 0 0.35rem;
}

.img-icon {
  height: 20px;
}

.item-box {
  display: flex;
  padding: 0.6rem 0;
  border-bottom: 1px solid #f5f5f5;

  .label {
    width: 2rem;
    min-width: 2rem;
    font-size: 0.4rem;
  }

  .content {
    flex: 1;
    font-size: 0.4rem;

    .input-style {
      width: 100%;
      border: none;
      outline: none;
    }

    input::placeholder {
      color: #c0c0c0;
      /* 设置placeholder的颜色 */
      opacity: 1;
      /* Firefox */
    }

    .placeholder {
      color: #c0c0c0;
      width: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .arrow_right {
      width: 30px;
      display: inline-block;
      color: #c0c0c0;
    }

    .sex-img {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
}

.submitSave {
  background-color: @REDCOLOR;
  color: white;
  width: 100%;
  border-radius: 1rem;
  text-align: center;
  padding: 0.5rem 0;
  font-size: 0.4rem;
  margin: 0.5rem 0;
  font-weight: bold;
}
.deleteBtn {
  background: white;
  color: @REDCOLOR;
  border: solid 1px @REDCOLOR;
}
</style>
